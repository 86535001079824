@blueGrayBackground: #768896;
@darkBlueGrayBackground: #343434;
@lightGrayBackground: #dee2e8;
@darkGrayBackground: #141414;

@secondaryHeaderHeight: 50px;

.trainingPage {
  background: @defaultGrayBackground;
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  flex: 1 0 auto;
}

.trainingPage.embedded {
  top: 0px;
}

.trainingPage .headerGrid .stretched.row {
  padding-top: 20px;
  padding-bottom: 0px;

  .resultsBtn {
    margin-left: 44%;
  }

  .resultsBtn:hover .button {
    background-color: darken(grey, 10);
  }
}

.trainingPage .moduleLaunchPanel .headerGrid .stretched.row {
  padding-top: 20px;
  padding-bottom: 35px;

  i {
    margin-bottom: 5px;
  }

  .resultsBtn {
    margin-left: 11%;
  }
}

.trainingPage .trainOthersContainer {
  margin-top: 30px;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100rem;
    margin: auto;
  }

  .headerInline {
    position: sticky;
    z-index: 20;
    padding-inline: 1rem;
    margin-bottom: -10px;
    background-color: @defaultGrayBackground;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 12rem;
      background-color: @defaultGrayBackground;
      bottom: calc(100% - 3.75rem);
      left: 0;
      background: linear-gradient(to bottom, @defaultGrayBackground 92.5%, transparent);
      z-index: -1;
    }
  }

  .userSelection {
    margin-top: 0px;
  }
  .curriculumSelectionPanel, .moduleLaunchPanel {
    margin-top: 5px;
  }
  .trainingMessagePanel + .curriculumSelectionPanel, .trainingMessagePanel + .moduleLaunchPanel {
    margin-top: 10px;
  }
}

.trainingPage .ui.stackable.grid.cardGrid {
  margin-bottom: 10px;
}

.myAccountPage, .myResultsPage {
  margin-top: 20px;
  .ui.header {
    padding-bottom: 10px;
    > i {
      margin-bottom: 8px;
    }
  }
}

.trainingMessagePanel {
  position: sticky;
  top: 45px;
  z-index: 30;
}

.trainingMessagePanel + .myAccountPage, .trainingMessagePanel + .myResultsPage {
  margin-top: 10px;
}

.trainOthersContainer .ui.header i.icon {
  margin-bottom: 8px;
  margin-right: 7px;
}

.trainingPage .moduleLaunchPanel .ui.header i.icon {
  margin-right: 7px;
}

.trainingPage {
  .curriculumSelectionPanel {
    margin-top: 20px;
  }
  .trainingMessagePanel + .curriculumSelectionPanel, .trainingMessagePanel + .moduleLaunchPanel {
    margin-top: 10px;
  }
}

.trainingPage .secondaryHeader {
  background-color: @blueGrayBackground;
  color: white;
  height: @secondaryHeaderHeight;
  line-height: @secondaryHeaderHeight;
  box-sizing: border-box;
  .grid {
    margin-top: 0;
  }
}

.trainingPage .secondaryHeader .sixteen.wide.column {
  padding-top: 0;
  padding-bottom: 0;
}

.trainingPage .secondaryHeader a {
  color: white;
  text-decoration: none;
}

.trainingPage .secondaryHeader a:hover {
  color: white;
  text-decoration: underline;
}

.trainingPage .secondaryHeader .ui.breadcrumb .active.section {
  font-weight: normal;
}

.trainingPage .ui.main.menu .item.userName {
  border-left: none;
}

.trainingPage .ui.main.menu .item.userName a {
  margin-right: 20px;
}
.trainingPage .ui.main.menu .item:before {
  background: none;
}

.trainingPage .ui.inverted.main.menu .current.item {
  font-weight: 600;
  border-bottom: 3px solid @immersiveYellow;
  margin-top: 4px;
  color: @immersiveYellow !important; // overriding semantic ui's !important white coloring for active items
}

.trainingPage .ui.main.menu .ui.container {
  margin-left: 54px !important;
  margin-right: 20px !important;
  width: 100%;
  @media only screen and (max-width: 1023px) {
    margin-right: 0px !important;
    margin-left: 14px !important;
  }
}

.trainingPage .ui.container.main .two.column.row {
  margin-top: 10px;
}

.trainingPage {
  .trainingMessagePanel {
    margin-top: 45px;
  }
  .trainingMessagePanel + .trainOthersContainer {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .trainingPage .username-xl {
    display: none;
  }
  .ui.inverted.main.menu .item {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (min-width: 1024px) {
  .trainingPage .username-xs {
    display: none;
  }
}

.trainingPage .backBtn {
  position: fixed;
  bottom: 40px;
  right: 60px;
  color: black;
  text-transform: uppercase;
  font-size: 16pt;
}

.trainingPage .backBtn .button {
  background-color: @blueGrayBackground;
  color: white;
}

.trainingPage .backBtn:hover .button {
  background-color: darken(grey, 10);
}

@media print {
  .trainingPage {
    overflow: visible;
  }
}

@media only screen and (max-width: 1479px) {
  .trainingPage {
    .backBtn {
      position: static;
      text-align: center;
      margin-bottom: 20px;
    }
    .headerGrid .stretched.row .resultsBtn {
      margin-left: 10%;
    }
    .moduleLaunchPanel .headerGrid .stretched.row .resultsBtn {
      position: relative;
      right: 220%;
    }
  }
}

@media only screen and (max-width: 990px) {
  .session-responsive {
    font-size: 0.8em!important;
  }
}

.myAccountPage {
  display: flex;
  flex-direction: column;
  height: auto;
}

.myResultsPage {
  display: flex;
  flex-direction: column;
  height: auto;
}

.trainingPage .ui.button {
  color: #e0e1e2;
  background: rgba(0,0,0,.6);
}

.trainingPage .ui.button.sendCode {
  white-space: nowrap;
  margin: 1.25em 1em 0 0;
}