
@import '../assets/fonts/open-sans.less';

@sideBarToggleWidthMin: 900px;
@sideBarToggleWidthMax: 899px;

@bodybackground: #f7f7f7;

@menuBackground: #353535;
@nearBlack: #333333;
@printBlack: #999999;
@printBorderThickness: 0.4mm;

@topNavigationBarHeight: 80px;

@immersiveYellow: #ffc82a;
@immersiveBlack: #343434;
@lightGray: #c2c2c2;
@defaultGrayBackground: #ebebeb;


/*
  Prevent Micorsoft Edge from displaying its build-in
  password revealer, which would double up on our own revealer.
*/
::-ms-reveal {
  display: none
}

.appContent .pageContent {
  padding-top: 8em;
  box-sizing: border-box;
}

#ihub {
  background-color: @bodybackground;
  overflow-x: auto;
}

.appContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pageContent {
  padding-bottom: 36px;
}

.ui.card {
  word-break: break-all;
}

#ihub .printOnly {
  display: none;
}

.verticalCenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.trainingContent {
  padding-bottom: 40px;
}

.adminMessagePanel {
  margin-bottom: 20px;
}

/*
 Fix issue with inputs within dropdowns within forms extending
 beyond the RHS of the drop down container.
*/
.ui.form .ui.dropdown .menu>.input {
  width: auto;
}

@media print {
  .ui.main.container.trainingContent {
    padding: 0;
    margin: 0;
  }

  .appContent .pageContent {
    padding-top: 0px;
  }

  /*
   This is necessary in order to print the session report via the trainee session results template
   as .ui.grid gives a 'display: flex' property and value which works in Chrome, however, does not
   work in Firefox. 'flex' and 'inline-flex' were the only values I could get to work in Chrome,
   so unfortunately I could not find a universal solution.
  */
  @-moz-document url-prefix() {
    .ui.main.container.trainingContent > .ui.grid {
      display: block;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .authAdmin .main.container {
    width: 968px;
  }
}

@media only screen and (max-width: 1250px) {
  .authAdmin .main.container {
    width: 768px;
  }
}

@media only screen and (max-width: 1060px) {
  .authAdmin .main.container {
    width: 568px;
  }
}

.ui.modal {
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

#topMenuItems {
  position: absolute;
  top:0;
  right: 20px;
  height:@topNavigationBarHeight;
}

/* http://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting-using-css */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

/* Visually support clicking on labels for checkboxes to check them
   by changing the cursor to a pointer and disabling text selection. */
.ui.checkbox label {
  .noselect;
  cursor: pointer;
}

@import 'admin_template.less';
@import 'manage_curriculum_enrollments_page.less';
@import 'main_header.less';
@import 'login_page.less';
@import 'module_launch_page.less';
@import 'enrollment_start_page.less';
@import 'recent_training_results.less';
@import 'edit_curriculum_page.less';
@import 'edit_group_page.less';
@import 'module_results.less';
@import 'side_bar.less';
@import 'coloured_row.less';
@import 'restore_archived_message.less';
@import 'admin_dashboard.less';
@import 'filter_selector.less';
@import 'matrix_table.less';
@import 'my_training.less';
@import 'trainee_page_layout.less';
@import 'video.less';
@import 'system_admin_page.less';
@import 'login_method_panel.less';
@import 'bad_license_page.less';
@import 'db_not_ready_page.less';
@import 'high_level_report.less';
@import 'setup_wizard.less';
@import 'pre_launch_modal';
@import 'products_version_panel.less';
@import 'dev_error_panel.less';
@import 'packages.less';
@import 'sim_in_progress.less';
@import 'manual_training.less';
@import 'module_expiry_modal.less';
@import 'single_curriculum_report.less';
@import 'reports.less';
@import 'results.less';
@import 'modules_page.less';
@import 'import_mode_selector.less';
@import 'curriculum_cert.less';
@import 'merge_users_page.less';
@import 'user_list.less';

@import 'print_general.less';
@import 'fixes.less';
