.packagesPanel {
  margin-bottom: 25px;
}

.verificationCodeDescription {
  // 14px of margin is consistent with the upload package form
  // for space between text and input fields
  margin-bottom: 14px;
}

.runtimeVersionMismatch {
  display: flex;
  color: red;
}

.runtimeVersionMismatchImage {
  margin-left: 10px;
}

.packagesTableHeadingPackageName {
  text-align: left;
  width: 45%;
}

.packagesTableHeading {
  text-align: left;
}
