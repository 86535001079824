.recentTrainingResultsPage .ui.feed>.event>.label {
  position: relative;
  width: 60px;
}

.recentTrainingResultsPage .event .label .line {
  border: 1px solid #89d0ca;
  height: 95%;
  position: absolute;
  top: 5px;
  left: 29px;
  z-index: -1;
}



.recentTrainingResultsPage .ui.feed>.event>.label img {
  width: auto;
  height: auto;
  border-radius: none;
}



