#safetyModalContainer {
  margin: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.safetyModalCenteringContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.safetyModal {
  z-index: 2000;
  font-size: 30px;
  height: 850px;
  width: 1511px;
  position: relative;
  flex-shrink: 0;

  > img {
    width: 100%;
    height: 100%;
  }

  .bodyText {
    position: absolute;
    top: 0;
    width: 100%;
    height: 655px;
  }

  h2 {
    font-size: 38px;
    font-weight: 800;
  }

  .motion-sickness-ct {
    position: absolute;
    z-index: 2000;
    left: 55px;
    top: 60px;
    width: 700px;
    color: #fffc00;
    h2 {
      margin-top: 0px;
    }
  }

  .clear-area-ct {
    position: absolute;
    z-index: 2000;
    width: 400px;
    left: 375px;
    text-align: left;
    color: #505457;
  }

  .avoid-tripping-ct {
    position: absolute;
    z-index: 2000;
    right: 406px;
    width: 425px;
    text-align: right;
    color: #505457;
  }

  .take-your-time-ct {
    position: absolute;
    z-index: 2000;
    left: 1130px;
    top: 220px;
    width: 400px;
    color: #fffc00;
    h2 {
      margin-top: 0px;
    }
  }

  .avoid-tripping-ct,
  .clear-area-ct {
    bottom: 0;
    padding: 0 10px;
    padding-bottom: 40px;
  }

  .footer-ct {
    position: absolute;
    z-index: 2000;
    left: 0px;
    top: 655px;
    color: white;
    width: 1511px;
    height: 195px;
    font-size: 13px;
    line-height: 1.4em;

    ul {
      margin: 0;
      padding-left: 20px;
    }
    h3 {
      font-weight: 600;
      font-size: 15pt;
      margin-bottom: 2px;
    }
    .footer-ct-section {
      position: absolute;
      padding: 10px;
      height: 100%;
      // In case the text overflows (usually happens with other languages, like russian)
      overflow-y: auto;
    }
    .footer1-ct {
      left: 0%;
      width: 24.35%;
      h3 {
        color: red;
      }
    }
    .footer2-ct {
      left: 24.95%;
      width: 47.75%;
      h3 {
        color: #e4e40e;
      }
    }
    .footer3-ct {
      left: 73.2%;
      width: 13.4%;
    }
    .footer4-ct {
      left: 87.15%;
      width: 12.9%;
    }
  }

  .alignPreLaunchModalButtonsRight {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
  }
}

// This media query is for making the modal fit on screens with a smaller resolution. In particular, it's for
// the laptops given to customers which have a 1920x1080 display and a recommended scale of 125%.
// This will likely not work correctly on retina displays because of the higher inherent DPI so this media query
// will make the modal appear smaller at all times. It will also not work on safari according to the MDN docs.
// Sources:
// (https://medium.com/@micjamking/hd-retina-display-media-queries-b5562b5430d6#:~:text=Apple's%20Retina%20display%20is%20a,326ppi%20to%20be%20more%20accurate),
// (https://developer.mozilla.org/en-US/docs/Web/CSS/resolution)
@media only screen and (max-width: 1920px) and (min-resolution: 1.25dppx) {
  .safetyModal {
    scale: 0.75;
  }
}
