#ihub {
  .colouredCheckbox input:focus + label:before,
  .colouredCheckbox input:checked + label:before {
    border-color: @immersiveYellow;
  }

  .colouredCheckbox input + label:after {
    background-color: @immersiveYellow;
    border-radius: 4px;
  }

  .colouredCheckbox.radio input + label:after {
    border-radius: 100%;
  }

  .colouredCheckbox input + label:after {
    color: white;
  }

  .colouredRow {
    cursor: pointer;
    transition: background-color 50ms ease-out;
  }

  .colouredRow:hover {
    background-color: #f2f2f2;
  }

  .colouredRow.selected{
    background-color: #ffe392;
  }
}