

.ui.inverted.main.menu.superuser {
  background-color: red;
}

.ui.inverted.main.menu {
  background: @menuBackground;
  height: 80px;

  .item {
    background: @menuBackground;
  }
}

.ui.inverted.main.menu .logo {
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui.inverted.main.menu img.logo.responsive {
  @media only screen and (max-width: 1023px) {
    max-width: 120px;
    display: block;
    padding-bottom: 5px;
  }
}

.authAdmin .ui.inverted.main.menu .container {
  margin-left: 60px !important;
  margin-right: 20px !important;
  width: 100%;
}

.headerIcons {
  #toggleSideMenuBtn {
    color: #e5e5e5;
    font-size: 25px;
    margin-left: 10px;
    cursor: pointer;
  }

  #toggleSideMenuBtn + a > img.logo {
    margin-left: 11px;
  }

  > a > img.logo {
    margin-left: 54px;
    margin-bottom: 2px;
  }
}
