

.adminPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.appContainer .adminPage .appContent {
  flex: 1 0 auto;
}

.authAdmin:not(.embedded) .main.container {
  margin-left: 245px !important;
}

.authAdmin .main.container.fluid {
  // Important necessary since semantic uses important for margin left.
  margin-left: inherit !important;
  padding-left: 245px;
  padding-right: 30px;
  width: 100%;
}

@media print {
  .authAdmin .main.container.fluid {
    margin-left: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }
}

.authAdmin.adminMenuHidden .main.container.fluid {
  padding-left: 30px;
}

.adminPage .ui.inverted.main.menu .current.item {
  font-weight: 600;
  border-bottom: 3px solid @immersiveYellow;
  margin-top: 4px;
  color: @immersiveYellow !important; // overriding semantic ui's !important white coloring for active items
}

.bottomAdminFooter {
  background-color: #fffbfb;
  border-top: solid 1px #dad8d8;
  flex-shrink: 0;
}

.bottomAdminFooter .ui {
  margin-bottom: -8px;
}

.bottomAdminFooter .ui.grid {
  margin: 0;
}

.bottomAdminFooter .ui.grid > .column {
  line-height: 100%;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: center;
}

.bottomAdminFooter .column img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

.bottomAdminFooter .footerLogo img {
  opacity: 0.9;
  margin-right: -25%;
}

.ui.message.newVersionAvailablePanel {
  margin-bottom: 30px;
}

.capslock-message {
  font-weight: 600;
}

@media print {
  .authAdmin.appContainer .main.container {
    margin-left: 0 !important;
  }
}