#dev_error_panel {
  position: fixed;
  bottom: 0;
  color: #fff;
  background-color: rgba(160, 0, 0, 0.9);
  width: 100%;
  z-index: 1000;
  font-size: 12pt;
  max-height: 200px;
  overflow: scroll;

  .errors {
    padding: 16px;
    white-space: pre;
    font-family: monospace;
  }

  .buttons {
    position: fixed;
    width: 100%;
    text-align: right;
    padding-right: 25px;

    .button {
      margin-top: 7px;
      margin-right: 0;
    }
  }
}
