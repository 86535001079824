.expiryOptions {
  padding-top: 20px;
}

.neverExpireOption {
  position: relative;
  top: -20px;
  left: 32px;
}

.expiryPeriodOption {
  display: flex;
  align-items: center;
  position: relative;
  top: -30px;
  left: 32px;

  div.ui {
    padding: 0px 15px;
  }
}

.expiryCountSelect {
  width: 125px;
}

.expiryPeriodSelect {
  width: 148px;
}
