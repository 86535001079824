.moduleLaunchPage {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-flow: column;
}


.moduleLaunchPage .pusher {
  position: relative;
  height: 100%;
}

.moduleLaunchPage .ui.segment.iframe-holder {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.moduleLaunchPage iframe#moduleContentIFrame {
  border: 0;
  width: 100%;
  height: 100%;
}


.ui.visible.left.sidebar ~ .pusher {
  margin-right: 260px;
}