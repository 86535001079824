.filterSelector .ui.checkbox label {
  cursor: pointer;
}

.dateSelector {
  height: 30.5px;

  &.ui.buttons {
    margin-right: 3px;
  }
}

.dateFilter input[type=text] {
  display: none;
}

// The position:absolute on the datepicker fixes an issue where it would push down
// the bottom of the container a little bit even when it's collapsed, making it not
// align properly with other similar containers.
.dateFilter .react-datepicker-wrapper {
  position: absolute;
}

// In order to make the clear date picker buttons reasonably sized, I had to wrap them
// in a div, unfortunately, this causes the 'buttons' css from Semantic-UI to no longer
// apply to them, as such we need to remove the top left and bottom left border radius
// properties to ensure that the buttons are flush with the rest of the date picker
.clearDatePickerButton {
  border-top-left-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
}
