
.ui.vertical.sticky.menu {
  display: none;
}

.ui.vertical.sticky.menu {
  background-color: @menuBackground;
  position: fixed;
  height: 100%;
  border-radius: 0;
  border-top: none;
  display: block;
}

.adminMenuHidden .ui.vertical.sticky.menu {
  top: 10px;
  width: 62px;
  height: 79px;
  box-shadow: none;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.adminMenuVisible .ui.vertical.sticky.menu {
  top: 80px;
}

.adminMenuHidden .ui.vertical.sticky.menu a i {
  color: #eee;
}

.adminMenuHidden .ui.vertical.sticky.menu .menuContent {
	display: none;
}

.ui.ui.vertical.sticky.menu .menuContent .group.item {
  margin-top: 15px;
}

.appContainer.authAdmin .ui.vertical.sticky.menu .menuContent .group.item {
  margin-top: 15px;
}

#ihub .menuContent {

  .item::before {
    display: none;
  }

  .item.current {
    background: @menuBackground;
    color: @immersiveYellow !important; // we have to use important here because semantic ui uses important as well.
    font-weight: 600;
  }

  .item.current::before {
    display: block;
    content: "";
    position: absolute;
    top: 4px;
    left: 1px;
    bottom: 6px;
    height: 30px;
    width: 3px;
    background-color: @immersiveYellow;
    border-radius: 0px 3px 3px 0px;
    transition: background-color 0.15s linear 0s;
  }

  .group.item {
    margin-top: 15px;
    text-transform: uppercase;
    font-size: small;
    font-weight: 600;
    border-bottom: 1px solid rgba(255,255,255,.08);
    color: grey;
  }
}