.curriculumMetadataLabels .ui.segment,
.curriculumCompletionsTable .ui.table {
  margin: 0px;
}

.curriculumMetadataLabels .ui.segment {
  border-bottom: none;
  box-shadow: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: max-content;
  margin-top: 40px;
}

.curriculumMetadataLabels .item {
  margin-right: 20px;
}

.curriculumCompletionsTable .ui.table {
  border-top-left-radius: 0px;
}
