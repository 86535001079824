.editCurriculumPage .editCurriculumPageContent {
  margin-top: 1rem;
}

.editCurriculumPage .draggable-item {
   cursor: pointer;
   cursor: -moz-grab;
   cursor: -webkit-grab;
}

.editCurriculumPage .item-being-dragged {
  background: #edf6f8;
}
.editCurriculumPage .item-being-dragged,
.editCurriculumPage .item-being-dragged span,
.editCurriculumPage .item-being-dragged .PlainEditable {
  opacity: 0;
}

.editCurriculumPage .item .content .PlainEditable {
  display: inline-block;
  width: 300px;
  padding: 2px;
  transition: border-color 0.2s linear;
  border: 1px solid transparent;
  vertical-align: middle;
}

.editCurriculumPage .item .content .PlainEditable p {
  margin: 0;
}

.editCurriculumPage .item .content .PlainEditable:hover {
  border: 1px solid #AAA;
  border-radius: 2px;
  cursor: text;
}

.editCurriculumPage .item .content span.actions {
  margin-left: 5px;
}

/* IE places a "cross" icon in the text input by default.  Remove it, as we
   have our own. */
.editCurriculumPage .curriculumModuleItem::-ms-clear {
  width: 0;
  height: 0;
}

.editCurriculumPage .curriculumModuleItem {
  border: 0px solid;
}

.editCurriculumPage .curriculumModuleItem:focus {
  border: 1px solid;
}

.editCurriculumPage .editCurriculumPageContent, .editModulePage .editModulePageContent {
  .imagePanel {
    .ui.card {
      width: 470px;
    }
  }

}
