.complete, .passed {
  color: #90A024;
}

.complete-alt {
  color: #56c76e;
}

.notAttempted {
  color: #aaa;
}

.withdrawn {
  color: #6e6e6e;
}

.incomplete, .started {
  color: #2185D0
}

.expired, .unsuccessful {
  color: #e26154;
}

.cancelled {
  color: #8a8a8a;
}

.expiring {
  color: #ecad35;
}
