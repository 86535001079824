
@lightGrayTimelineColor: #c2c2c2;
@enrollmentStart_padding: 20px;
@curriculumSidebar_size: 25rem;
@enrollmentStart-contentArea_gap: 3.25rem;

.enrollmentStart-mainArea {
  display: flex;
  justify-content: center;
  height: fit-content;
  width: 100%;
  margin-top: 30px;

  .enrollmentStart-contentArea {
    display: flex;
    // Max area of module view minus spacing for curriculum sidebar
    max-width: calc(100rem - @curriculumSidebar_size);
    // Spacing for sidebar + gap between curriculum and page edge
    margin-left: calc(@curriculumSidebar_size + @enrollmentStart_padding);
    width: 100%;
    // Gap between sidebar + module view area
    padding-left: @enrollmentStart-contentArea_gap;
    // Gap between module view (accounting for gap between sidebar + module view) + page edge
    padding-right: calc(@enrollmentStart-contentArea_gap + @enrollmentStart_padding);

  }

  .newBadge {
    position: absolute;
    padding: 0.025rem 0.5rem;
    border-radius: 4px;
    background-color: #ffc42a;
    color: black;
    font-weight: 700;
    font-size: 0.75rem;
  }
}

.curriculumSidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  width: @curriculumSidebar_size;
  padding: 10px;
  background-color: white;
  color: #202020;
  border-radius: 6px;
  // Full page height minus header height, minus spacing from header and page bottom (20px each)
  max-height: calc(100vh - 80px - 40px);
  position: fixed;
  margin-left: calc(-@curriculumSidebar_size - @enrollmentStart-contentArea_gap);
  overflow-y: auto;

  .header {
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    border-bottom: 1px solid #cccccc;
    margin: 0;
  }

  .curriculumEntry {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 7.5px 12.5px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;

    @bg-transition-speed: 50ms;
    transition: background-color @bg-transition-speed ease-in-out;

    &.focused {
      background-color: #ebebeb;

      transition: background-color @bg-transition-speed ease-in-out;
    }

    &:not(.focused):hover {
      background-color: whitesmoke;

      transition: background-color @bg-transition-speed ease-in-out;
    }

    .icon {
      font-size: 20px;

      &.expired {
        color: #e15b4d;
      }

      &.expiringSoon {
        color: #ecad35;
      }
    }

    .curriculumEntry-textArea {
      display: flex;
      flex-direction: column;
      gap: 2px;

      &.newBadge-spacing {
        margin-right: 2.75rem;
      }

      .title {
        font-size: 14px;
        margin: 0;
      }

      .curriculumDetails {
        font-size: 12px;
        color: #999999;
      }
    }

    .newBadge {
      top: 0.5;
      right: 0.5rem;
    }
  }
}

.moduleLaunchPanel {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;

  .moduleLaunchPanelHeader {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    z-index: 20;
    background-color: @defaultGrayBackground;
    margin-left: -1rem;

    &::before {
      content: '';
      position: absolute;
      width: calc(100% + 3rem);
      height: 25rem;
      background-color: @defaultGrayBackground;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    &::after {
      content: '';
      position: absolute;
      width: calc(100% + 3rem);
      height: 1.35rem;
      background: linear-gradient(to bottom, @defaultGrayBackground 25%, transparent);
      top: 100%;
      left: 0;
      z-index: -1;
    }

    .input {
      margin-right: -40px;
    }
  }

  .timelineWrapperPanel {
    padding-top: 0;
    margin-bottom: 100px;
    width: 100%;
  }

  .timelineItem {
    position: relative;
    margin-bottom: 12px;
    width: 100%;
  }

  .timelineItem.launchable .timelinePanel {
    cursor: pointer;
  }

  .timelineItem.not-launchable .timelinePanel {
    cursor: not-allowed;
  }

  .statusLine {
    height: 50%;
    position:absolute;
    left: -1.5px;
    border-left: 3px solid;
  }

  .statusLine.top {
    top: 0;
  }

  .statusLine.bottom {
    top: 50%;
    height: 60%;
  }

  .timelineItem.first .statusLine.top {
    visibility: hidden;
  }

  .timelineItem.last .statusLine.bottom {
    visibility: hidden;
  }

  .timelineItem.first.last .timelineCircle {
    visibility: hidden;
  }

  .timelineItem.done .statusLine,
  .timelineItem.next .statusLine.top {
    border-color: @nearBlack;
  }

  .timelineItem.pending .statusLine,
  .timelineItem.next .statusLine.bottom {
    border-color: @lightGrayTimelineColor;
  }

  /* Even if an item is done show the top status line as not done if
     the preceeding items is not done. */
  .timelineItem.done.previousNotComplete .statusLine.top {
    border-color: @lightGrayTimelineColor;
  }

  /* Even if an item is done show the bottom status line as not done if
     the preceeding items is not done. */
  .timelineItem.done.previousNotComplete .statusLine.bottom {
    border-color: @lightGrayTimelineColor;
  }

  .timelineItem {
    .ribbon {
      position: absolute;
      z-index: 10;
      top: 10px;
      left: 26px;
    }

    .timelinePanel {
      background-color: white;
      color: #202020;
      left: 40px;
      position: relative;
      height: 100%;
      width: 100%;
      transition: height 200ms, width 200ms;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      border-radius: 6px;
      overflow: hidden;
      border: 4px solid white;

      @bg-transition-speed: 50ms;
      transition: background-color @bg-transition-speed ease-in-out;

      &:hover {
        background-color: whitesmoke;

        @bg-transition-speed: 50ms;
        transition: background-color @bg-transition-speed ease-in-out;
      }
    }

    .taskImage {
      height: 125px;
      width: auto;

      &.complete {
        opacity: 0.5;
      }
    }

    .timelineTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 0.5rem;
    }

    .timelineTitle-main {
      text-transform: none;
      letter-spacing: 1px;
      text-align: center;
      max-width: 90%;
      margin: 0;
      word-break: break-word;
    }

    .timelineTitle-sub {
      font-style: italic;
      font-weight: 600;
      color: #999999;
    }

    .timelineStatus {
      height: 125px;
      font-size: 8pt;
      text-align: center;
      min-width: 11rem;
    }

    .newBadge {
      top: 0.3rem;
      right: 0.3rem;
    }
  }

  .timelineItem .timelineCircle {
    @size: 15px;

    margin-top: calc(-@size / 2);
    top: 50%;
    left: calc(-@size / 2);
    width: @size;
    height: @size;
    background-color: @nearBlack;
    border: 3px solid @nearBlack;
    border-radius: 50%;
    display: block;
    position: absolute;
  }

  .timelineItem.next .timelineCircle {
    background-color: @lightGrayTimelineColor;
  }

  .timelineItem.pending .timelineCircle {
    background-color: @lightGrayTimelineColor;
    border-color: @lightGrayTimelineColor;
  }
}

.progressBadge .icon {
  margin: 0;
}

.progressBadge, .moduleData {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.moduleData {
  width: 100%;
  // margin: auto;
  margin-top: 25px;
  font-size: 10px;

  .smallExpiryWarning {
    display: none;
  }
}

.timelineStatus .moduleData {
  color: #888;
  padding-bottom: 20px;
}
