@media print {

  // If you change the container settings (eg body, html, appContainer or adminPage) please test on IE11 as some
  // of these rules have been tuned to fix print quirks with it when printing the session reports.
  body, html {
    height: initial;
    overflow-x: initial;
    overflow-y: initial;
  }

  @page {
    size: "A4 portrait";
    margin: 10%;
  }

  .appContainer {
    overflow-y: auto;
    position: static;
    height: auto;
  }

  .adminPage {
    display: block;
    flex-direction: initial;
    height: auto;
  }

  .noPrint {
    display: none !important;
  }

  .printOnly {
    display: inherit !important;
  }

  .breakBefore {
    page-break-before: always;
  }

  .appContainer {
    position: initial;
    overflow-y: initial;
    overflow-x: initial;
  }

  .appContainer .adminPage .appContent {
    flex: initial;
  }
  .appContent .pageContent > .ui.main.container {
    padding-top: 0;
    margin-top: 0;
  }

  .appContent .pageContent > .ui.main.container > .ui.grid {
    margin: 0; // Remove negative margins.
  }

  .ui.attached.message, .ui.message,
  .ui.attached.positive.message, .ui.positive.message,
  .ui.attached.info.message, .ui.info.message,
  .ui.attached.error.message, .ui.error.message,
  .ui.attached.warning.message, .ui.warning.message {
    box-shadow: none;
  }

  .ui.attached.message, .ui.message {
    border: solid 1px #999999
  }

  .ui.attached.positive.message, .ui.positive.message {
    border: solid 1px #A3C293
  }

  .ui.padded.raised.segment {
    box-shadow: none;
    padding: 0;
    border: none;
    margin: 0;
  }

  .ui.attached.info.message, .ui.info.message {
    border: solid 1px #A9D5DE
  }

  .ui.attached.error.message, .ui.error.message {
    border: solid 1px #C9BA9B
  }

  .ui.attached.warning.message, .ui.warning.message {
    border: solid 1px #C9BA9B
  }

  .ui.segment {
    box-shadow: none;
  }

  .ui.menu {
    box-shadow: none;
    border: @printBorderThickness solid  @printBlack;
  }

  .ui.striped.table tbody tr:nth-child(2n), .ui.striped.table>tr:nth-child(2n) {
    background: none;
  }


  .ui.table {
    border: @printBorderThickness solid  @printBlack;
    border-collapse: collapse; // Fixes issue with some borders on cells not printing.
  }

  .ui.table thead th {
    border-bottom: @printBorderThickness solid  @printBlack;
  }
  .ui.table tr td {
    border-top: @printBorderThickness solid  @printBlack;
  }
  .ui.celled.table tr td, .ui.celled.table tr th {
    border-left: @printBorderThickness solid  @printBlack;
  }

  .ui.definition.table td:nth-child(2), .ui.definition.table tfoot:not(.full-width) th:nth-child(2), .ui.definition.table thead:not(.full-width) th:nth-child(2) {
    border-left: @printBorderThickness solid @printBlack;
  }

  .ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child {
    border-left: none;
  }

  .ui.celled.definition.table thead:not(.full-width) th:first-child {
    box-shadow: none;
  }

  .ui.celled.table tr:last-child td:first-child {
    border-bottom-left-radius: .28571429rem;
  }

  .ui.selection.dropdown {
    border: none;
    padding: 0;
  }

  .ui.selection.dropdown > i.dropdown.icon {
    display: none;
  }

  .strike {
    position: relative;
  }

  .strike > .grayFiller {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ui.card, .ui.cards>.card {
    box-shadow: none;
    border: @printBorderThickness solid  @printBlack;
  }

  .ui.card>.extra, .ui.cards>.card>.extra {
    border-top: @printBorderThickness solid  @printBlack !important;
  }
}
