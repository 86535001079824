.trainingPage.site-login-form {

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;

  .column {
    max-width: 450px;
  }

  .columnResetPwd {
    max-width: 550px;
  }

  .loginAreaWrapper {
    margin-top: 60px;
    background-color: @darkBlueGrayBackground;

    a {
      color: #94bce4;
    }

    a:hover {
      color: #c7d9ea;
    }
  }

  .segment.loginInputsPanel {
    background: none;
    padding-bottom: 40px;
    border: none;
    border-radius: 0;
    box-shadow: none
  }

  .segment.loginInputsPanel .header {
    color: white;
    margin-bottom: 0;
  }

  .loginInputsPanel .explanation {
    color: white;
    margin-bottom: 20px
  }

  .segment.loginInputsPanel label {
    color: white;
    font-weight: normal;
  }

  .ui.container.loginButtonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .ui.button.loginButton {
    margin: 20px 0px 0px 0px;
    color: #3D300B;
    background-color: #FFC82C;
    flex-grow: 1;

    &:hover {
      background-color: darken(#FFC82C, 20);
    }
  }

  .loginField {
    margin-top: 30px;
  }

  .loginInfoContainer {
    .ui.button {
      color: @defaultGrayBackground;
      background-color: transparent;

      i {
        background-color: transparent;
      }
    }
  }

  .main.login-page-content {
    padding-top: 160px;
  }

  .login-logo-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .login-form-logo {
    max-width: 100%;
    margin-top: 50px;
  }

  .login-form {
    margin-left: 10%;
    margin-right: 10%;
  }

  .ui.search.dropdown>.text {
    width: inherit;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .capslock-tooltip {
    position: absolute;
    left: 100%;
    margin-top: 5px;
    font-weight: 600;
    width: fit-content;
    white-space: nowrap;
  }

  .segment.loginForgotPwd {
    background: none;
    padding-right: 0px;
    padding-bottom: 0px;
    border: none;
    border-radius: 0;
    box-shadow: none
  }

  .segment.loginForgotPwd a {
    &:hover {
      text-decoration: underline
    }
  }

  .segment.alreadyHaveToken {
    background: none;
    padding-bottom: 0px;
    border: none;
    border-radius: 0;
    box-shadow: none
  }

  .statusIndicators {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
  }

  .login-divider {
    position: relative;
    display: block;
    font-weight: normal;
    color: @defaultGrayBackground;
    margin: 20px 0px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: .5px;
      width: 40%;
      background-color: @defaultGrayBackground;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .ui.button.microsoft-login {
    background-color: white;
    border: 1px #8C8C8C solid;

    &:hover {
      background-color: darken(white, 20);
    }

    .icon.microsoft-login-icon {
      margin: 0 12px 0 0;
    }
  }
}

@media only screen and (max-width: 500px) {
  .login-page-banner {
    width: 80%;
  }

  .column {
    margin-left: 0;
    margin-right: 0;
  }

  .login-form .ui.header {
    margin-bottom: 20px;
  }

  .login-form {
    margin-left: 0;
    margin-right: 0;
  }

  .trainingPage.site-login-form .login-logo-container {
    padding-left: 0;
    padding-right: 0;
  }

  .trainingPage.site-login-form .login-form-logo {
    width: 80%;
  }
}

.login-page-banner {
  max-width: 100%;
  margin-top: 100px;
}


.segment.loginInputsPanel .loader {
  margin-top: 40px;
  margin-bottom: 20px;
}

.ui.grid.powered-by {
  margin-top: 20px;
  margin-bottom: 15px;
  font-style: italic;
  font-size: medium;
  font-weight: 600;
}