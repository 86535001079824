// This fix is required when placing a loader within a modal using the 'dimmer' class
// Without it, the loader's colors get inverted because it thinks it is being placed directly within
// the dimmer, and it can't be seen on the white background.

.ui.dimmer .ui.modal .ui.loader:before {
  border-color: rgba(0, 0, 0, 0.0980392);
}

.ui.dimmer .ui.modal .ui.loader:after {
  border-top-color: rgb(118, 118, 118);
}

// this fix is required to be able to run elearning content in iOS.
// Semantic applies specific ios rules in the sidebar to workaround some issues with ios.
// these issues were fixed in latest version iOS. Latest version of semantic UI have removed their ios specific css.
// see file sidebar.less and search for iOS in https://github.com/Semantic-Org/Semantic-UI/blob/master/RELEASE-NOTES.md
html.ios {
  overflow-x: unset;
  -webkit-overflow-scrolling: unset;
}

html.ios,
html.ios body {
  height: unset;
}
