
.addMembersPanel {
	max-height: 700px;
	overflow-y: auto;
	padding-right: 20px;
	padding-top: 20px;
	margin-top: 10px;
}

.addMembersList {
	padding-bottom: 20px;
}