// Allow scrolling the table with sticky headers and a sticky first column
.table-scroll {
  position: relative;
  width: 100%;
  table {
    width: 100%;
  }

  div.legend-pagination-header {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    max-height: 38px;

    .ui.menu {
      height: 38px;
      min-height: 38px;
    }

    .legend-container {
      width: 100%;
      max-width: 550px;
      .ui.segment {
        height: 38px;
        padding: 0.4em 0.4em;
        .list {
          white-space: nowrap;
        }
      }
    }
  }

  div.legend-pagination-footer {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.matrixTable {

  td.result {
    text-align: center;
  }

  td, th {
    min-width: 120px;
    max-width: 120px;
  }

  th:first-child:not(.tableChildHeader) {
    min-width: 250px;
  }

  .borderedCurriculum {
    border-right-style: double;
    border-right-color: rgba(34,36,38,.15);
  }
}

i.lightGrey.icon {
  color: #e2e2e2!important;
}


@media print {
  #matrixTableLegend {
    border-radius: 0;
  }
}

#ihub .paginatingTable {

  thead th {
    word-wrap: break-word;
    background-color: #eaeaea;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  tr:first-child th:first-child, td:first-child {
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    border-right: 1px solid rgba(34,36,38,.15); // Set the border on the first column so it stays as we scroll
  }

  tr:first-child th:nth-child(2), td:nth-child(2) {
    border-left: none;
  }

  tr:nth-child(2) th:first-child {
    border-left: 1px solid rgba(34,36,38,.15);
  }

  thead tr:first-child th:first-child {
    z-index: 5; // The top left corner needs to be above everything in case we scroll in both directions
  }

  thead tr:nth-child(2) th {
    top: @topNavigationBarHeight + 42px; // top Navigation bar + height of first row
  }

  td.dateAlign {
    text-align: center;
  }

  td.numberAlign {
    text-align: right;
  }

  td.iconAlign {
    text-align: center;
  }
}

// Ensure the first colomn does not get hidden behind the side bar
#ihub .adminMenuVisible .paginatingTable {
  tr:first-child th:first-child, td:first-child {
    left: 210px; // This is the width of the side bar.
  }
}

// Ensure that when sticky on th admin area only, the sticky headers stays under the navigation bar
// it is not required on the trainee area, because the scroll area starts under the navigation bar
#ihub .authAdmin .paginatingTable {
  thead th {
    top: @topNavigationBarHeight; //this is the height of the Top navigation bar
  }
}

// For printing, we ensure the position is reset to its normal behavior.
@media print {
  #ihub .paginatingTable {
    thead tr th, tr:first-child th:first-child, td:first-child {
      left: 0px;
      top: 0px;
      z-index: 0;
      position: inherit;
    }
  }
}

// When scrolling horizontally, we need to ensure the first column is masking the other column,
// i.e is not transparent as per the default.
// To achieve this we set some colors on the rows.
#ihub table.ui.selectable.paginatingTable {
  tbody tr {
    td {
      background: white;
    }
  }
  tbody tr:hover {
    td {
      background: rgb(240, 240, 241);
    }
  }
}

.react-datepicker-popper {
  z-index: 6; // Override this to make sure its on top of the table after the changes above
}