.certificatePrintBtn {
  top: 40px;
  right: 60px;
}

.curriculumCertificateContainer {
  margin-top: 150px;
  display: flex;
  justify-content: center;

  .frame {
    display: flex;
    position: relative;
    height: 15cm;       // A4 landscape
    width: 29.7cm;
    @page {
      size: A4 landscape;
    }
    background-color: #ffffff;
    border: 8px solid #ffc000;

    @backgroundWidth: 275px;

    .backgroundImage {
      position: absolute;
      height: 14.6cm;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 9;
      padding-left: calc(@backgroundWidth / 2);   // include white area in background image in text center alignment

      .header {
        display: flex;
        flex-direction: column;
        font-size: 3em;
        align-items: center;
        height: 2.6em;
        justify-content: space-around;
        margin-top: 1em;
      }

      .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4em;
        justify-content: space-around;
        height: 10em;
        gap: 50px;                      // date and time slightly further down

        .userDetails {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;

          .textline {
            display: flex;
            align-items: baseline;
            gap: 10px;
          }
        }

        .text {
          font-size: 1.5em;

          &.variable {
            text-decoration: underline 2px;
            text-underline-offset: 0.3em;   // underline distanced from text
            line-height: 1.6;               // leave space below the distanced underline before the next line
            text-align: center;             // word wrap is centered for long curriculum names
            max-width: 700px;               // avoid text overlapping with right hand edge of cert frame

            &:after {
              content: "\00a0\00a0\00a0\00a0";  // underline extends beyond word length to the right
            }

            &:before {
              content: "\00a0\00a0\00a0\00a0";  // underline extends beyond word length to the left
            }
          }
        }
      }

      .footer {
        display: flex;
        align-self: flex-start;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        margin-top: auto;
        margin-bottom: 1em;
        padding-left: calc(@backgroundWidth / 3);   // partial overlap with background image

        .logo {
          max-height: 120px;
          max-width: 230px;

          &.immersive {
            margin-bottom: 20px;
          }
        }

        .branding {
          display: flex;
          flex-direction: column;
          margin-right: 50px;

          .expiry {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .frame::before {          // frame inner border
    content: "";
    position: absolute;
    top: 1mm;
    left: 1mm;
    width: calc(100% - 2mm);
    height: calc(100% - 2mm);
    border: 2px solid #ffc000;
    box-sizing: border-box;
  }

  .alternateText {
    max-width: 50%;
  }
}