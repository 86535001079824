.moduleResultsPage .ui.styled.accordion .title {
  color: rgba(0, 0, 0, 0.95);
  font-weight: 400;

  span.error {
    color: red;
  }
}

.trainingPage > .moduleResultsPage {
  margin-top: 17px;
}

.moduleResultsPage .ui.styled.accordion .title [class*="right floated"] {
   float: right;
}

.moduleResultsPage .ui.styled.accordion .title .resultIndicator {
  min-width: 2.2em;
  min-height: 2.2em;
  color: rgba(34,36,38,.6);
}

.moduleResultsPage .ui.styled.accordion .title .ui.label.result i,
.moduleResultsPage .ui.styled.accordion .title .resultIndicator i {
  margin: 0;
}

.trainingPage > .moduleResultsPage h1.ui.header {
  margin-bottom: 0.5rem;
  + .ui.padded.segment {
    margin-top: 37px;
  }
  > i {
    margin-bottom: 8px;
  }
}

.moduleResultsPage h2.ui.header {
  margin-top: 1em;
}

.moduleResultsPage h3.ui.header {
  margin-top: 1em;
}

.moduleResultsPage .ui.styled.accordion .title.notExpandable {
  cursor: default;
}

.moduleResultsPage .ui.styled.accordion .title.notExpandable i.dropdown.icon {
  opacity: 0;
}

.moduleResultsPage h2.lessonTitle,
.moduleResultsPage div.lessonTitle {
  border-bottom: 1px solid #ddd;
  padding-bottom: 6px;
  margin-bottom: 4px;
}

.moduleResultsPage .duration {
  color: #777
}

.moduleResultsPage .lessonPanel.ui.segment {
  margin-bottom: 15px;
}

.moduleResultsPage .lessonPanel .lessonGrid {
  padding-top: 10px;
}

.moduleResultsPage .lessonPanel.segment.simcontrol_scenario {
  border-left: solid 8px #fece6c;
}

.moduleResultsPage .lessonPanel.segment.vr_training {
  border-left: solid 8px rgba(99, 134, 162, 0.73);
}

.moduleResultsPage .digChartContainer {
  margin-top: 6px;
}

.moduleResultsPage .subModuleResultsAdminContainer {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.gaugeMetrics {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.metricSummary {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.moduleResultsPage .ui.image.moduleImage {
  margin: 0px 0px 25px 0px;
}

.moduleResultsPage {
  .checklistItem .icon {
    display: table-cell !important; // Override the font-awesome icon 'inline-block' styling and use semantic-ui list styling
    min-width: 20px;
  }
}

@media print {

  .moduleResultsPage > .ui.grid {
    display: block; // Necessary for Firefox not to truncate page 1
    margin: 0; // Necessary for Chrome not truncate items that have page-break-before: always set.
  }

  .reportData.print.sixteen.wide.column {
    display: block; // Necessary for Firefox not to truncate page 1
  }

  .moduleResultsPage h2.lessonTitle,
  .moduleResultsPage div.lessonTitle {
    border-bottom: 1px solid @printBlack;
  }


  .moduleResultsPage .lessonPanel.segment {
    page-break-inside: avoid;
    border: none;
  }

  .moduleResultsPage .lessonPanel.segment thead {
    display: table-row-group; // Fixes issues with header sometimes overprinting
  }

  .moduleResultsPage .digChartContainer {
    border: none;
    padding: 0;
  }

  .moduleResultsPage .brandingLogo {
    max-height: 120px;
    max-width: 200px;
  }

  .moduleResultsPage .resultsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .noGrid {
    display: inherit;
    flex-direction: inherit;
    flex-wrap: inherit;
    align-items: inherit;
    padding: inherit;
    margin: inherit;

    > .column {
      padding: inherit !important;
      margin: inherit !important;
      display: inherit !important;
    }
  }

  // This is needed to allow the session reports to print somewhat reasonably in FireFox.
  // Due to bugs in FireFox, it cannot render flex or inline-block styles properly when
  // printing, thus we have to disable most .ui.grid classes of semantic-ui
  // which rely on flex heavily.
  div.ui.main.container.trainingContent {
    padding-top: 0px !important;

    > .ui.grid {
      .noGrid;
    }
  }

  // See above comment. required for firefox printing
  .ui.main.container.moduleResultsPage {
    padding-top: 0px !important;
    // This is required so IE does not print scroll bars
    overflow: hidden !important;

    > .ui.grid {
      .noGrid;
    }

    // Here we make the module information a bit more print friendly
    // by displaying the data across the top of the page.
    .ui.cards > .ui.card {
      border: none;
      width: 100%;

      p {
        display: inline-block;
        margin-right: 30px;
      }

      > .extra.content > .right.floated {
        float: left !important;
      }
    }

    // fixes spacing issues
    .gaugeMetrics {
      padding-bottom: 40px;
    }

    // significantly neatens the formatting
    .printPageBreak {
      page-break-before: always;
    }

    .metricSummary {
      page-break-inside: inherit; // Allows the table to print print from the first page
      padding: 0;

      > .content > .description > .ui.celled.compact.striped.table {
        // Without this on the second page of results, the table
        // wont have any borders
        border-collapse: inherit !important;
      }

    }

    .lessonPanel {
      page-break-inside: inherit; // Allows the table to print print from the first page
      padding: 0;
      margin-top: 0;

      > .ui.grid {
        .noGrid;

        > .column {
          display: inline-block !important;
        }

        // Without this on the second page of results, the table
        // wont have any borders
        table, td {
          border-collapse: inherit !important;
        }

        tr {
          page-break-inside: avoid !important; // Stop rows from getting split in half
        }
      }
    }
  }

  .trainingPage {
    position: inherit !important;

    > div {
      margin-top: 0px !important;
    }
  }
}
