.resultSelectionUserFilter .ui.input {
  width: 100%;
  max-height: 85%;
}

.resultSubmissionPaddedGrid {
  padding-top: 35px;
  padding-bottom: 35px;
}

.resultSelectionTableGrid {
  min-height: 420px;
}

.resultSelectionTableSubtitle {
  font-weight: bold;
  top: 35%;
  position: absolute;
}

.resultSelectionInstructions {
  color: lightgray;
  top: 50%;
  position: absolute;
  left: 20%;
}

.resultSelectionUserList {
  max-height: 290px;
  overflow-y: auto;

  .resultCell {
    padding-left: 50px !important; // Align the content of the cell with the RHS
  }
}

.resultSelectionResultsToSubmitList {
  max-height: 350px;
  overflow-y: auto;
  padding-right: 15px;
}

.resultSelectionUserListFooter {
  position: absolute;
  bottom: 0;
  min-width: 95%;

  .listFooterSubtitle {
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.resultSubmissionContent {
  font-weight: bold;
  font-size: medium;
}

.ui.horizontal.label.resultTypeLabel {
  min-width: 100px;
  text-align: center;
}
