.setupWizard {
  padding-top: 30px;
  text-align: center;

  > div {
    display: inline-block;
    width: auto;
    text-align: left;
  }

  .currentStep {
    margin-bottom: 20px;


    .registerTenant{

      .onlineLink{
        font-style: italic;
        font-size: small;
      }

      .newAdmin{
        margin-top: 50px;
      }
    }
  }

  .step .content {
    max-width: 235px;
  }
}

.setupWizard[dir="rtl"] {
  > div {
    text-align: right;
  }
  .ui.vertical.steps .active.step:after {
    display: none;
  }
  .loader,.icon {
    margin: 0 10px;
  }
}

.setupLanguage {
  height: 100vh;
  display: flex;
  justify-content: center;

  .colCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .button {
      margin: 10px;
      width: 250px;
      height: 90px;
    }
  }
}
