.highLevelReport {
  .ui.header {
    margin: 5px;
    display: inline;
  }

  .ui.segment:after {
    content: '';
    display: block;
    clear: both;
  }

  .ui.segments {
    border-top: 0;
  }

  .ui.styled.accordion {
    margin-bottom: 12px;

    .title {
      padding: 20px;
    }

    .title.noexpand{
      cursor: default;
    }

    .title > .icon {
      font-size: 18px;
    }

    .title > .icon.hidden {
      visibility: hidden;
    }
  }

  .gauge {
    margin: 20px;
  }

  .demiBold {
    font-weight: 600;
  }

  .title .badge {
    width: 103px;
    height: 71px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 1;
    transition: opacity 0.1s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .progressBadge {
      line-height: 15px;

      span {
        font-size: 11px;
      }
      .icon {
        font-size: 40px;
      }
    }

    .progressBadge.passedBadge .logo {
      width: 50px;
    }

    .progressBadge.incompleteBadge .icon {
      font-size: 36px;
    }

    .progressBadge.completedBadge .logo {
      width: 52px;
    }
  }

  .title.active .progressBadge {
    opacity: 0;
    transition: opacity 0.1s linear;
  }

  .subModuleResults {
    .resultBadge {
      float: right;
      width: 100px;
      padding-top: 25px;

      .progressBadge {
        transform: scale(0.8);
      }

      .score {
        text-align: center;
        font-weight: 600;
        font-size: 17pt;
        color: #444;
        padding-top: 12px;
      }
    }

    .clearFloat {
      clear: both;
    }
  }

  .traineeHeader {
    background-color: #768896;
    padding: 1px;
    display: flex;

    .header {
      flex-grow: 1;
      color: #fff;
      padding: 20px 30px;
    }

    .result {
      display: flex;
      flex-direction: column;

      .badge, .score {
        flex-grow: 1;
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        min-width: 100px;
      }

      .badge {
        background-color: #E4E4E4;
        border-top-right-radius: 2px;

        .progressBadge {
          transform: scale(0.8);
        }
      }

      .score {
        background-color: #DBDBDB;
        border-bottom-right-radius: 2px;
        font-weight: 600;
        color: #444;
        line-height: 27px;

        .value {
          font-size: 17pt;
        }
      }
    }
  }
}
