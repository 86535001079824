.adminDashboardPage div.image {
  height: 250px;
  text-align: center;
}

#ihub .adminDashboardPage i {
  line-height: 250px;
}

.adminDashboardPage .ui.cards > .card {
  width: 270px;

  > .content:not(:lang(ja)) {
    word-break: keep-all;
  }

  > .image > i {
    color: @immersiveBlack;
  }
}
