.reportBody {
  margin-top: 20px;
}

.curriculumStatus {
  display: inline-block;
  min-width: 120px;
  margin-bottom: 15px;
}

#ihub .curriculumReportTabs {
  .tab {
    padding-right: 0px;
    padding-left: 0px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    background-color: @bodybackground;
  }

  a {
    background-color: @bodybackground;
  }
}

.mandatoryStar::before {
  content: '*';
  color: red;
  margin-top: -0.2em;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-right: 0.2em;
}

.columnSubHeader {
  font-size: 0.8em;
}

.reportDropdown {
  min-width: 350px;
}
