.products_version_panel {

  .downloadProgress {
    // This moves the label up to overlap the progress bar.
    // (normally it sits underneath it).
    margin: 0 !important;
    .label {
      top: 0 !important;
    }

    // Make the progress bar the same width as the buttons.
    // Also stops it from being shrunk so the text goes outside of the bar.
    width: 160px;
  }

  .downloadProgress:lang(fr) {
    margin: 0 !important;
    .label {
      top: 0 !important;
    }

    width: 225px;
  }
  .downloadProgress:lang(ja) {
    margin: 0 !important;
    .label {
      top: 0 !important;
    }

    width: 200px;
  }

  // Fix the rows of the table to be the same height regardless of
  // whether they contain buttons or not.
  .ui.celled.compact.table td {
    height: 47px;
  }

  .check_for_updates_container {
    display: inline-flex;
    width: 100%;
    margin-bottom: 15px;
    align-content: center;
  }

  .check_for_updates_button {
    margin-left: auto;
  }
}

