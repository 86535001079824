#ihub .userList {
  &.scrollable {
    overflow-y: auto;
  }

  table {
    border: none;

    thead.scrollable {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}