@FontPathOpenSans: ".";

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: normal;
  src: url('@{FontPathOpenSans}/Regular/OpenSans-Regular.eot');
  src: url('@{FontPathOpenSans}/Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('@{FontPathOpenSans}/Regular/OpenSans-Regular.woff') format('woff'),
       url('@{FontPathOpenSans}/Regular/OpenSans-Regular.ttf') format('truetype'),
       url('@{FontPathOpenSans}/Regular/OpenSans-Regular.svg#OpenSansRegular') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: italic;
  src: url('@{FontPathOpenSans}/Italic/OpenSans-Italic.eot');
  src: url('@{FontPathOpenSans}/Italic/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
       url('@{FontPathOpenSans}/Italic/OpenSans-Italic.woff') format('woff'),
       url('@{FontPathOpenSans}/Italic/OpenSans-Italic.ttf') format('truetype'),
       url('@{FontPathOpenSans}/Italic/OpenSans-Italic.svg#OpenSansItalic') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('@{FontPathOpenSans}/Semibold/OpenSans-Semibold.eot');
  src: url('@{FontPathOpenSans}/Semibold/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
       url('@{FontPathOpenSans}/Semibold/OpenSans-Semibold.woff') format('woff'),
       url('@{FontPathOpenSans}/Semibold/OpenSans-Semibold.ttf') format('truetype'),
       url('@{FontPathOpenSans}/Semibold/OpenSans-Semibold.svg#OpenSansSemibold') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: italic;
  src: url('@{FontPathOpenSans}/SemiboldItalic/OpenSans-SemiboldItalic.eot');
  src: url('@{FontPathOpenSans}/SemiboldItalic/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
       url('@{FontPathOpenSans}/SemiboldItalic/OpenSans-SemiboldItalic.woff') format('woff'),
       url('@{FontPathOpenSans}/SemiboldItalic/OpenSans-SemiboldItalic.ttf') format('truetype'),
       url('@{FontPathOpenSans}/SemiboldItalic/OpenSans-SemiboldItalic.svg#OpenSansSemiboldItalic') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-style: normal;
  src: url('@{FontPathOpenSans}/Bold/OpenSans-Bold.eot');
  src: url('@{FontPathOpenSans}/Bold/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('@{FontPathOpenSans}/Bold/OpenSans-Bold.woff') format('woff'),
       url('@{FontPathOpenSans}/Bold/OpenSans-Bold.ttf') format('truetype'),
       url('@{FontPathOpenSans}/Bold/OpenSans-Bold.svg#OpenSansBold') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-style: italic;
  src: url('@{FontPathOpenSans}/BoldItalic/OpenSans-BoldItalic.eot');
  src: url('@{FontPathOpenSans}/BoldItalic/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('@{FontPathOpenSans}/BoldItalic/OpenSans-BoldItalic.woff') format('woff'),
       url('@{FontPathOpenSans}/BoldItalic/OpenSans-BoldItalic.ttf') format('truetype'),
       url('@{FontPathOpenSans}/BoldItalic/OpenSans-BoldItalic.svg#OpenSansBoldItalic') format('svg');
}

