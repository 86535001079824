.curriculumSelectionPanel {

  // Variables
  @title-height: 50px;
  @caption-height: 40px;

  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;

  .main {
    margin: 20px;
  }

  .cardGrid {
    margin-bottom: 100px;
  }

  .expiryWarning .icon {
    font-size: 2em !important;
  }

  .curriculumCard {
    cursor: pointer;
    border-radius: 10px;
    padding: 2px;
    background-color: white;

    @bg-transition-speed: 50ms;
    transition: background-color @bg-transition-speed ease-in-out;

    &:hover {
      background-color: whitesmoke;
      transition: background-color @bg-transition-speed ease-in-out;
    }

    img {
      width: 100%;
      display: block;
      border-radius:10px 10px 0px 0px;
    }

    .icon {
      margin: 0;
    }

    .ui.dimmer {
      z-index: 0;
    }

    .overlayBackground {
      border-bottom: 1px #e9e9e9 solid;
      font-size: 16px;
      letter-spacing: 1px;
      text-align: left;
      display: flex;
    }

    .captionOverlayBackground {
      font-size: 14px;
      letter-spacing: 1px;
      text-align: left;
      display: flex;
    }

    .curriculumStatus {
      position: absolute;
      top: 30px;
      right: 30px;
      display: flex;
      align-Items: center;
      min-width: unset;
      gap: 10px;
    }

    .overlayBackground {
      color: @immersiveBlack;
      font-size: 1.2em;
      font-weight: 600;
      height: @title-height;
      line-height: @title-height;
      position: relative;
      left: 0;
      right: 0;
      display: flex;
    }

    .captionOverlayBackground {
      color: @lightGray;
      height: @caption-height;
      line-height: @caption-height;
      bottom: 0;
      width: 100%;
      border-radius: 0px 0px 10px 10px;
    }

    .title, .caption {
      display: flex;
      line-height: 20px;
      vertical-align: middle;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
      margin-right: 10px;
      align-self: center;
    }

    .curriculumFadeComplete {
      background: rgba(223, 212, 212, 0.65);
      bottom: 0px !important;
      top: auto !important;
    }

    .newBadge {
      position: absolute;
      bottom: 1.75rem;
      right: 1.75rem;
      padding: 0.025rem 0.5rem;
      border-radius: 4px;
      background-color: #ffc42a;
      color: black;
      font-weight: 700;
      font-size: 0.75rem;
    }
  }

  @media (min-width: 0px) and (max-width: 768px) {
    .column {
        width: 100% !important;
    }
  }
  @media (min-width: 769px) and (max-width: 1536px) {
    .column {
      width: 50% !important;
    }
  }
  @media (min-width: 1537px) and (max-width: 2304px) {
    .column {
        width: calc(100% / 3) !important;
    }
  }
  @media (min-width: 2305px) and (max-width: 3072px){
    .column {
        width: 25% !important;
    }
  }
  @media (min-width: 3073px){
    .column {
        width: calc(100% / 5) !important;
    }
  }
}

.trainingPage > .ui.error.message {
  max-width: 55%;
  margin-top: 20px;
  left: 25%;
  margin-right: -25%;
}

.expired.expiredStatus {
  color: white;
}

.started.incompleteStatus {
  color: white;
}

.complete.completeStatus {
  color: white;
}

.expiring.expiringSoonStatus {
  color: white;
}
