.importModeSelector {
  label {
    .radio {
      bottom: -2px;
    }
    small {
      padding-left: 27px;
      position: relative;
      top: -3px;
    }
  }
}
