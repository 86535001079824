#mergeUsersPage {
  height: calc(100vh - 112px - 141px);
  display: flex;
  flex-direction: column;

  .mergeUsersPanel {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .primaryUserTitle {
      margin-top: 2px;
    }

    .duplicateUsersTitle {
      margin-bottom: 6px;
    }
  }

  .navigationContainer {
    margin-top: auto;

    > div {
      margin-top: 16px;
    }
  }
}

#mergeUsersPrimaryUserPanel {
  .primaryUserTitle {
    margin-bottom: 6px;
  }
}

#mergeUsersDuplicateUsersPanel {
  .primaryUserTitle {
    margin-bottom: -4px;
  }
}

#mergeUsersReviewPanel {
  .primaryUserTitle {
    margin-bottom: 0px;
  }

  .warningMessage {
    margin: 0;
    width: fit-content;
    padding: 10px 14px;
  }
}