.brandingPanel img {
  max-width: 100%;
}

.licensedProductsPanelButton {
  min-width: 160px;
}

.licensedProductsPanelButton:lang(fr) {
  min-width: 225px;
}

.licensedProductsPanelButton:lang(ja) {
  min-width: 200px;
}